<template>
  <div>
    <v-container>
      <div class="mx-auto text-center">
        <h1
          class="text-title example"
          @click.prevent="
            playSound(
              'https://firebasestorage.googleapis.com/v0/b/once-manolo-v2-2.appspot.com/o/g-once.mp3?alt=media&token=3149f9de-aea5-4543-9c2b-3cc4e105fd65'
            )
          "
        >
          El 11 <br />
          de Manolo
        </h1>
      </div>
      <div class="text-center pt-10 mx-auto container-75">
        <p class="lead">
          Manuel "Manolo" Fernández, periodista de "Los Tenores"de Radio ADN,
          arma equipos de fútbol según la contingencia y efemérides nacionales e
          internacionales. Escucha los más de 100 <i>oncenos</i> que se han y
          siguen conformándose.
        </p>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Hero",
  methods: {
    playSound(sound) {
      if (sound) {
        var audio = new Audio(sound);
        audio.play();
      }
    },
  },
};
</script>

<style scoped>
.text-title {
  font-family: "Tourney", sans-serif;
  font-size: 7rem;
  line-height: 1;
  cursor: pointer;
  text-transform: uppercase;
  display: inline-block;
}

span {
  transform: rotate(-4deg) !important;
}

.container-75 {
  width: 75%;
}

p.lead {
  font-family: "Spartan", sans-serif;
  line-height: 2.5;
  font-size: 0.9rem;
  font-weight: 500;
}

/* Extra small 600px and down) */
@media only screen and (max-width: 600px) {
  .text-title {
    font-size: 4.5rem;
  }
}
</style>
